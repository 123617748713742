import MiraclLogo from "../../../assets/img/miracl-logo.svg?react";

interface Props {
    fill?: string;
    width?: string;
}

export const Logo = ({ fill, width = "100%" }: Props) => {
    return <MiraclLogo fill={fill ?? "#5c4df6"} width={width} className="logo" />;
};
