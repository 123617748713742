import { Role } from "@miraclapp/mortgaging-shared";
import { ApplicationRoute } from "./shared/types/route";
import {
    mdiAccount,
    mdiAccountGroup,
    mdiAccountTie,
    mdiCalculator,
    mdiCalendarClockOutline,
    mdiCog,
    mdiDeleteOutline,
    mdiFileDocument,
    mdiFileSend,
    mdiFileSign,
    mdiFileUpload,
    mdiHandshake,
    mdiHome,
    mdiHomeSearch,
    mdiLaptopAccount,
    mdiListBoxOutline,
} from "@mdi/js";

type RouteOption = {
    route: ApplicationRoute;
    label: string;
    icon: string;
    dealDependent: boolean;
    isAllowed?: (role: Role) => boolean;
};

export const routeOptions: RouteOption[] = [
    {
        route: ApplicationRoute.HOME,
        label: "sidebar.navigation_menu.home",
        icon: mdiHome,
        dealDependent: false,
        isAllowed: (role) => role !== Role.CUSTOMER && role !== Role.SURVEY_USER,
    },
    {
        route: ApplicationRoute.CALCULATOR,
        label: "sidebar.navigation_menu.calculator",
        icon: mdiCalculator,
        dealDependent: false,
        isAllowed: (role) => role !== Role.CUSTOMER && role !== Role.SURVEY_USER,
    },
    {
        route: ApplicationRoute.CUSTOMER_DATA,
        label: "sidebar.navigation_menu.customerData",
        icon: mdiLaptopAccount,
        dealDependent: true,
        isAllowed: (role) => role !== "CUSTOMER" && role !== Role.SURVEY_USER,
    },
    {
        route: ApplicationRoute.HOUSE_BANK_OFFER,
        label: "sidebar.navigation_menu.houseBankOffer",
        icon: mdiHomeSearch,
        dealDependent: true,
        isAllowed: (role) => role !== "CUSTOMER" && role !== Role.SURVEY_USER && role !== Role.COMPANY,
    },
    {
        route: ApplicationRoute.SCHEDULER,
        label: "sidebar.navigation_menu.scheduler",
        icon: mdiCalendarClockOutline,
        dealDependent: true,
        isAllowed: (role) => role !== Role.SURVEY_USER && role !== Role.COMPANY,
    },
    {
        route: ApplicationRoute.CONSULTATION,
        label: "sidebar.navigation_menu.consultation",
        icon: mdiAccountTie,
        dealDependent: false,
        isAllowed: (role) => role !== Role.SURVEY_USER && role !== Role.COMPANY,
    },
    {
        route: ApplicationRoute.SURVEY,
        label: "sidebar.navigation_menu.survey",
        icon: mdiListBoxOutline,
        dealDependent: false,
        isAllowed: (role) => role === Role.SURVEY_USER,
    },
    {
        route: ApplicationRoute.HUB,
        label: "sidebar.navigation_menu.hub",
        icon: mdiFileUpload,
        dealDependent: false,
        isAllowed: (role) => role === Role.CUSTOMER || role === Role.DEVELOPER,
    },
    {
        route: ApplicationRoute.DEAL,
        label: "sidebar.navigation_menu.deal",
        icon: mdiFileSign,
        dealDependent: true,
        isAllowed: (role) => role !== Role.CUSTOMER && role !== Role.SURVEY_USER,
    },
    {
        route: ApplicationRoute.DOCUMENTS,
        label: "sidebar.navigation_menu.documents",
        icon: mdiFileDocument,
        dealDependent: true,
        isAllowed: (role) => role !== Role.CUSTOMER && role !== Role.SURVEY_USER,
    },
    {
        route: ApplicationRoute.CLOSER,
        label: "sidebar.navigation_menu.closer",
        icon: mdiHandshake,
        dealDependent: true,
        isAllowed: (role) => role !== Role.CUSTOMER && role !== Role.SURVEY_USER && role !== Role.COMPANY,
    },
    {
        route: ApplicationRoute.SUBMISSION,
        label: "sidebar.navigation_menu.submission",
        icon: mdiFileSend,
        dealDependent: true,
        isAllowed: (role) => role !== Role.CUSTOMER && role !== Role.SURVEY_USER && role !== Role.COMPANY,
    },
    {
        route: ApplicationRoute.COMPANIES,
        label: "sidebar.navigation_menu.companies",
        icon: mdiAccountGroup,
        dealDependent: false,
        isAllowed: (role) => [Role.DEVELOPER, Role.SUPER_USER].includes(role),
    },
    {
        route: ApplicationRoute.USERS,
        label: "sidebar.navigation_menu.users",
        icon: mdiAccount,
        dealDependent: false,
        isAllowed: (role) => [Role.DEVELOPER, Role.SUPER_USER].includes(role),
    },
    {
        route: ApplicationRoute.SETTINGS,
        label: "sidebar.navigation_menu.settings",
        icon: mdiCog,
        dealDependent: false,
        isAllowed: (role) => role !== Role.CUSTOMER && role !== Role.SURVEY_USER && role !== Role.COMPANY,
    },
    {
        route: ApplicationRoute.TRASH,
        label: "sidebar.navigation_menu.trash",
        icon: mdiDeleteOutline,
        dealDependent: true,
        isAllowed: (role) => role !== Role.CUSTOMER && role !== Role.SURVEY_USER && role !== Role.COMPANY,
    },
];
