export enum TrueFalseValue {
    TRUE = "true",
    FALSE = "false",
}

export enum JaNeinValue {
    JA = "Ja",
    NEIN = "Nein",
}

export enum AgreeWithValue {
    ZU = "zu",
    NICHT_ZU = "nicht zu",
}

export enum VariableOriginReference {
    EURIBOR_1 = "EURIBOR 1",
    EURIBOR_3 = "EURIBOR 3",
    EURIBOR_6 = "EURIBOR 6",
    EURIBOR_12 = "EURIBOR 12",
    ESCALATION_CLAUSE = "Zinsgleitklausel",
}

export enum VariableOriginReferenceShort {
    EURIBOR_1 = "EUR1",
    EURIBOR_3 = "EUR3",
    EURIBOR_6 = "EUR6",
    EURIBOR_12 = "EUR12",
}

export enum FixedOriginReference {
    SWAP_5 = "SWAP 5J",
    SWAP_10 = "SWAP 10J",
    SWAP_15 = "SWAP 15J",
    SWAP_20 = "SWAP 20J",
    SWAP_25 = "SWAP 25J",
    SWAP_30 = "SWAP 30J",
}

export enum DealPropertyType {
    SINGLE_FAMILY_HOUSE = "Einfamilienhaus",
    APARTMENT_PROPERTY_TYPE = "Wohnung",
    TOWN_HOUSE = "Reihenhaus",
    DOUBLE_HOUSE = "Doppelhaushälfte",
    LAND_PROPERTY_TYPE = "Grundstück",
    COMMERCIAL_PROPERTY = "Gewerbliche Immobilie",
}

export enum DealPropertyUse {
    RENTAL = "Vermietung",
    SECURITY = "Besicherung",
    SECURITY_AND_RENTAL = "Besicherung und Vermietung",
    INTERIM_FINANCING = "Zwischenfinanzierung",
}

export enum DealPropertyFinancingType {
    PURCHASE = "Kauf einer Immobilie",
    REFINANCE = "Umschuldung",
    RENOVATION = "Sanierung",
    INTERIM_FINANCING = "Zwischenfinanzierung",
    CONSTRUCTION = "Bauvorhaben",
    RETIREMENT_APARTMENT = "Vorsorgewohnung",
    COOPERATIVE_APARTMENT = "Genossenschaftswohnung",
    COOPERATIVE_CONTRIBUTION = "Genossenschaftsbeitrag",
    CONSUMER_CREDIT = "Konsumkredit",
    OTHER = "Sonstiges",
}

export enum DealPropertyObjectPurpose {
    OWN_USE = "Eigengebrauch",
    RENT_OUT = "Vermietung",
}

export enum DealPropertyBasementType {
    SIMPLE_STORAGE = "Lagerkeller einfach",
    HIGH_QUALITY_STORAGE = "Lagerkeller hochwertig",
    SUITABLE_FOR_RESIDENTAL_USE = "Keller mit Wohnnutzeignung",
}

export enum DealPropertyWindowType {
    WOOD = "Holz",
    PLASTIC = "Kunststoff",
    ALUMINIUM = "Aluminium",
}

export enum BorrowerType {
    KN1 = "Kreditnehmer 1",
    KN2 = "Kreditnehmer 2",
    KN3 = "Kreditnehmer 3",
    KN4 = "Kreditnehmer 4",
}

export enum DealPropertyExteriorSurfaceType {
    BALCONY = "Balkon",
    ROOF_TERRACE = "Dachterrasse",
    GARDEN = "Garten",
    LOGGIA = "Loggia",
    TERRACE = "Terrasse",
    WINTER_GARDEN = "Wintergarten",
}

export enum DealPropertyAtticType {
    FULL = "Voll ausgebaut",
    PARTIALY_EXPANDED = "Teilweise ausgebaut",
    NOT_EXPANDED = "nicht ausgebaut",
    FLAT_ROOF = "Flachdach",
}

export enum DealPropertyOrientationType {
    NORTH = "Norden",
    EAST = "Osten",
    SOUTH = "Süden",
    WEST = "Westen",
}

export enum DealPropertyRealEstateOrientationType {
    NO_CHOICE = "Keine Auswahl",
    INNER_COURTYARD_OR_RESIDENTIAL_STREET_ORDEAD_END = "In Innenhof, Wohnstr., Sackgasse",
    THOROUGHFARE_OR_RAILWAY_FACILITIES = "Zu durchgangsstr., Bahnanlagen",
    SIDE_STREET = "Zu Nebenstraße",
    PARKS_OR_GREEN_SPACES = "zu Parkanlagen, Grünanlagen",
}

export enum DealPropertyHeatingSystemType {
    DISTRICT_HEATING = "Fernwärme",
    SOLID_WOOD_CHIPS_PELLET = "Festbrennstoffe (Hackschnitzel, Pellets)",
    SOLID_COAL = "Festbrennstoffe (Kohle/Koks)",
    SOLID_WOOD = "Festbrennstoffe (Holz)",
    GAS = "Gas",
    SOLAR = "Solar",
    ELECTRICITY = "Strom",
    HEAT_PUMP = "Wärmepumpe",
    OIL = "Öl",
}

export enum DealPropertyAdditionalHeatingSystemType {
    TILED_STOVE = "Kacheloffen",
    AIR_CONDITIONER = "Klimaanlage",
    CONTROLLED_RESIDENTAL_VENTILATION = "Kontrollierte Wohnraulüftung",
    OPEN_FIREPLACE = "Offener Kamin",
    SOLAR_SYSTEM = "Solaranlage",
}

export enum DealPropertyEquipmentFeatureType {
    ELABORATE_GARDEN_DESIGN = "Aufwendige Gartengestaltung",
    CARPORT = "Carport",
    HIGH_QUALITY_FENCING = "Einfriedung aus hochwertigen Elementen",
    HIDDEN_GARDEN = "Garten nicht einsehbar",
    GARDEN_IRRIGATION = "Gartenbewässerung",
    ARTIFICIAL_LANDSCAPING = "Künstliche Geländegestaltung",
    SWIMMING_POOL = "Schwimmbad",
    ELECTRIC_GARAGE_DOOR = "Elektrisches Garagentor",
    GARAGE_DOOR = "Garagentor",
    EXCLUSIVE_FLOOR_COVERINGS = "Exklusive Bodenbeläge",
    FLOOR_HEATING = "Flächenheizung",
    GENEROUS_EXPOSURE_AREA = "Großzügige Belichtungsfläche",
    HIGH_QUALITY_INTERIOR_DOORS = "Hochwertige Innentüren",
}

export enum DealPropertyElementType {
    BUILDING = "Bauwerk",
    ROOFING = "Dachdeckung",
    EXTERIOR_FACADE = "Außenfasade",
    GENERAL_AREAS = "Allgemeinflächen",
    WINDOW = "Fenster",
    HEATING = "Heizung",
    ELECTIRCAL_AND_SANITAL_INSTALLATION = "Elektro-Sanitärinstallation",
    PAINT_AND_TAPESTRY_WORK = "Maler/Tapazirarbeiten",
    INTERIOR_PLASTER = "Innenputz",
}

export enum DealPropertyBathroomFurnitureType {
    BATHTUB = "Badewanne",
    SHOWER = "Dusche",
    SEPARATE_UNDERFLOOR_HEATING = "Separate Fußbodenheizung",
    TIKING_ENTIRE_BATHROOM = "Verfliesung des gesamnten Bades",
    WC = "WC",
}

export enum BorrowerEmploymentType {
    EMPLOYED = "Angestellt",
    WORKER = "Arbeiter:in",
    CIVIL_SERVICE = "Beamte:r",
    SELF_EMPLOYED = "Selbstständig",
    SEASONAL = "Saisonarbeiter:in",
    PENSION = "Pension",
    MATERNITY_LEAVE = "Karenz",
    OTHER = "Sonstige",
}

export enum BorrowerAdditionalIncomeType {
    RENT = "Miete",
    SUSTAINABLE_REWARDS = "Nachhaltige Prämien",
    OTHER_BONUSES = "Sonstige Prämien",
    TIP = "Trinkgeld",
    FAMILY_ALLOWANCE = "Familienbeihilfe",
    PARENTAL_LEAVE = "Elternkarenz",
    EDUCATIONAL_LEAVE = "Bildungskarenz",
    CHILDCARE_ALLOWANCE = "Kinderbetreuungsgeld",
    ORPHANS_OR_WIDOWS_PENSION = "Waisen/Witwenpension",
    ALIMONY = "Alimente",
    DISABILITY_PENSION = "Invaliditätspension",
    COMPENSATION_FOR_EXPENSES = "Aufwandsentschädigung",
    FUTURE_RENT = "Zukünftige Miete",
}

export enum BorrowerMaritalStatus {
    SINGLE = "ledig",
    MARRIED = "verheiratet",
    LIVING_IN_REGISTERED_PARTNERSHIP = "in eingetragener Partnerschaft lebend",
    DIVORCED = "geschieden",
    DISSOLVED_REGISTERED_PARTNERSHIP = "aufgeloste eingetragene Partnerschaft",
    WIDOWED = "verwitwet",
    SURVIVING_REGISTERED_PARTNER = "hinterbliebener eingetragener Partner",
    LIFE_COMMUNITY = "lebensgemeinschaft",
}

export enum MortgageExtrapaymentWish {
    ZERO = "0",
    TEN_THOUSAND = "10k",
    FIFTEEN_THOUSAND = "15k",
    HUNDRED_PERCENT = "100%",
}

export enum DealAdditionalLiabilityType {
    MORTGAGE = "Hypothekarkredit",
    CONSUMER_LOAN = "Konsumkredit",
    CHART_OF_ACCOUNTS = "Kontorahmen",
    LEASING = "Leasing",
    ALIMONY = "Alimente",
    RENT = "Miete",
    SUBSIDIES = "Förderung",
    ZERO_PERCENT_FINANCING = "0% Finanzierung",
    BUILDING_SAVINGS_CONTRACTS = "Bausparverträge",
    GUARANTEES = "Bürgschaften",
}

export enum DealAdditionalLiabilityWhatWillHappen {
    RESCHEDULING = "Wird umgeschuldet",
    WILL_BE_PAID_OFF = "Wird getilgt",
    WILL_REMAIN = "Bleibt bestehen",
    WILL_NOT_REMAIN = "Wird aufgelöst",
}

export enum RelatedObjectType {
    FINANCING_OBJECT = "Finanzierungsobjekt",
    EXISTING_OBJECT_1 = "Bestandsobjekt 1",
    EXISTING_OBJECT_2 = "Bestandsobjekt 2",
    EXISTING_OBJECT_3 = "Bestandsobjekt 3",
    EXISTING_OBJECT_4 = "Bestandsobjekt 4",
}

export enum DealAdditionalLiabilityFixedOrVariable {
    FIXED = "Fix",
    VARIABLE = "Variabel",
    BULLET = "Endfällig",
}

export enum Country {
    AUSTRIA = "Österreich",
}

export enum AustriaState {
    WIEN = "Wien",
    NIEDERÖSTERREICH = "Niederösterreich",
    OBERÖSTERREICH = "Oberösterreich",
    SALZBURG = "Salzburg",
    TIROL = "Tirol",
    VORARLBERG = "Vorarlberg",
    BURGENLAND = "Burgenland",
    STEIERMARK = "Steiermark",
    KÄRNTEN = "Kärnten",
}

export enum NumberOfMonthsEmployed {
    THREE = 3,
    SIX = 6,
    NINE = 9,
    TWELVE = 12,
}

export enum DealPropertyParkingSpotType {
    GARAGE = "Garage",
    PARKING_PLACE = "Stellplatz",
    CARPORT = "Carport",
}

export enum DealPropertyRenovationType {
    BUILDING = "Bauwerk Sanierungsjahr",
    ROOFING = "Dachdeckung Sanierungsjahr",
    EXTERIOR_FACADE = "Außenfasade Sanierungsjahr",
    COMMON_AREAS = "Allgemeinflächen Sanierungsjahr",
    WINDOWS = "Fenster Sanierungsjahr",
    HEATING = "Heizung Sanierungsjahr",
    ELECTRICAL_SANITARY_INSTALLATIONS = "Elektro/Sanitärinstallation Sanierungsjahr",
    PAINTING_WALLPAPERS = "Maler/Tapezierarbeiten Sanierungsjahr",
    INTERIOR_PLASTER_STAIRS = "Innenputz/Innentreppen Sanierungsjahr",
}

export enum ClosedLostReasonValues {
    NOT_ACHIEVABLE_SUMMARIZED = "nicht machbar gesamt",
    NOT_ACHIEVABLE_LTV = "nicht machbar LTV",
    NOT_ACHIEVABLE_HHR = "nicht machbar HHR",
    BAD_INPUT = "Schlechte Anfrage (schon erledigt, nur schauen, Versehen...)",
    WRONG_SUBJECT = "Falsches Thema (PK, Leasing, PK Umschulden)",
    NOT_REACHED_OR_NO_ANSWER = "nie erreicht / keine Rückmeldung",
    FORGOT_DUE_TO_LATE_CONTACTING = "Verloren da zu spät kontaktiert",
    COMPETITION_HOUSE_BANK = "Konkurrenz (Hausbank)",
    COMPETITION_OTHER = "Konkurrenz (andere)",
    MULTIPLE_REQUEST_LEAD = "Mehrfachanfrage selber Lead",
    SPAM = "Spam",
    TEST = "Test",
    NO_NUMBER = "no Number",
    REAL_ESTATE_APPRAISAL = "Immobilienbewertung",
}

export enum ClosedLostReasonOtherValues {
    LIT = "LIT",
}

export enum ClosedLostReasonSingleValues {
    TOO_LITTLE_DOWNPAYMENT_LTI_DSTI_KSV = "zu wenig Eigenmittel",
    NOT_REACHED = "nie erreicht",
    NO_ANSWER = "keine Rückmeldung",
    NOT_SEARCHING = "Nicht mehr auf der Suceh",
    NO_LEAD = "Kein Lead",
    COMPETITION = "Konkurrenz",
    CONSUMER_LOAN = "Konsumkredit",
    WRONG_NUMBER = "Falsche Nummer",
    PURCHASE_PLANS_SMASHED = "Kaufvorhaben zerschlagen",
    INCONCRETE = "Unkonkret",
    COMMERCIAL_LOAN = "Gewerblicher Kredit",
    OTHER = "Andere",
    NO_BETTER_SOLUTION_FROM_US = "Keine bessere Lösung unsererseits",
    NO_ANSWER_WAITING_FOR_OFFER = "keine Rückmeldung - Warten auf Angebot",
    NO_ANSWER_DATE_PROPOSED = "keine Rückmeldung - Terminvorschlag",
    NO_ANSWER_WAITING_FOR_SOMETHING_IN_GENERAL = "keine Rückmeldung - Warten auf irgendwas",
    NO_ANSWER_SIGHTSEEING = "keine Rückmeldung - Besichtigung",
    NO_ANSWER_OTHER = "keine Rückmeldung - Andere",
    NO_ANSWER_FOLLOW_UP = "keine Rückmeldung - Follow Up",
    NO_ANSWER_ARCHIVE = "keine Rückmeldung - Archiv",
}

export enum ClosedLostReasonSingleFormOptionValues {
    TOO_LITTLE_DOWNPAYMENT_LTI_DSTI_KSV = "zu wenig Eigenmittel",
    NOT_REACHED = "nie erreicht",
    NO_ANSWER = "keine Rückmeldung",
    NOT_SEARCHING = "Nicht mehr auf der Suceh",
    NO_LEAD = "Kein Lead",
    COMPETITION = "Konkurrenz",
    CONSUMER_LOAN = "Konsumkredit",
    WRONG_NUMBER = "Falsche Nummer",
    PURCHASE_PLANS_SMASHED = "Kaufvorhaben zerschlagen",
    INCONCRETE = "Unkonkret",
    COMMERCIAL_LOAN = "Gewerblicher Kredit",
    OTHER = "Andere",
    NO_BETTER_SOLUTION_FROM_US = "Keine bessere Lösung unsererseits",
}

export enum BorrowerIncomeCurrencyValues {
    EUR = "EUR",
    USD = "USD",
    CHF = "CHF",
}

export enum BorrowerWayOfLivingType {
    PROPERTY = "Eigentum",
    COOPERATIVE = "Genossenschaft",
    MAIN_RENT = "Hauptmiete",
    SUBLEASE = "Untermiete",
    MUNICIPAL_HOUSING = "Gemeindewohnung",
    SERVICE_APARTMENT = "Dienstwohnung",
    PARENTS = "Eltern",
}

export enum BorrowerRentalIncomeType {
    GROSS = "Brutto",
    NET = "Netto",
}

export enum BorrowerReferralType {
    FORMER_CUSTOMER = "formerCustomer",
    MIRACL_WORKER = "miraclCoworker",
    COOPERATION_PARTNER = "cooperationPartner",
    GOOGLE = "google",
    YOUTUBE = "youtube",
    FACEBOOK = "facebook",
    INSTAGRAM = "instagram",
    TWITTER = "twitter",
    NEWSPAPER = "newspaper",
    RADIO = "radio",
    TV = "tv",
    WILLHABEN = "willhaben",
    IMMOSCOUT = "immoscout",
    OTHER = "other",
}

export enum BorrowerHouseBank {
    ANADI_BANK = "Anadi Bank",
    BAWAG = "BAWAG P.S.K.",
    BANK_99 = "Bank99",
    BAUSPARKASSE_WUSTENROT = "Bausparkasse Wüstenrot AG",
    BAUSPARKASSE_OST = "Bausparkasse der österreichischen Sparkassen AG",
    EASY_BANK = "easyBank",
    ERSTE = "Erste Bank der österreichischen Sparkassen AG",
    HYPO = "HYPO NOE Landesbank für Niederösterreich und Wien AG",
    N26 = "N26",
    OBERBANK = "Oberbank AG",
    RAI_BAU = "Raiﬀeisen Bausparkasse Gesellschaft m. b. H.",
    RAI_NW = "Raiﬀeisenlandesbank Niederösterreich-Wien AG",
    SANTANDER = "Santander Consumer Bank",
    VR = "VR Bank",
    VOLKSBANK = "Volksbank Niederösterreich",
    VOLKSBANK_W = "Volksbank Wien",
    WSK = "WSK Bank",
    START = "start:bausparkasse",
    OST_ARTZE = "Österreichische Ärzte & Apotheker Bank",
    UNICREDIT = "UniCredit Bank Austria AG",
    OTHER = "Andere",
}

export enum DealNextStep {
    WAITING_FOR_OFFER = "Warten auf Angebot",
    DATE_PROPOSED = "Terminvorschlag",
    WAITING_FOR_SOMETHING_IN_GENERAL = "Warten auf irgendwas",
    SIGHTSEEING = "Besichtigung",
    OTHER = "Andere",
    FOLLOW_UP = "Follow Up",
    ARCHIVE = "Archiv",
}

export enum BorrowerEmploymentIndependenceType {
    SOLE_PROPRIETORSHIP = "Einzelunternehmen",
    GMBH_WITHOUT_EMPLOYEES = "GmbH ohne Mitarbeiter",
    GMBH_WITH_EMPLOYEES = "GmbH mit Mitarbeiter",
}

export enum DealPropertyPredominantUsageType {
    RENTAL = "Vermietung",
    OWN_USE = "Eigennutzung",
    INTENTION_TO_SELL = "Verkaufsabsicht",
    PRIVATE_USE = "private Nutzung",
    COMMERCIAL_USE = "gewerbliche Nutzung",
}

export enum DealAdditionalWealthType {
    SECURITIES_DEPOSIT = "Wertpapierdepot",
    BUILDING_SAVINGS_ACCOUNT = "Bausparer",
    LIFE_INSURANCE = "Lebensversicherung",
    SAVINGS_BOOKS = "Sparbücher",
    ACCOUNT_OR_CASH = "Konto/Bar",
    COMPANY_SHARES = "Firmenanteile",
}

export enum BorrowerResidenceType {
    PERMANENT_RESIDENCE_PERMIT = "Daueraufenthaltstitel",
    RED_WHITE_RED_CARD = "Rot-Weiß-Rot-Karte",
    RED_WHITE_RED_CARD_PLUS = "Rot-Weiß-Rot-Karte plus",
    CONVENTIONAL_PASSPORT = "Konventionalpass",
    FAMILY_MEMBER = "Familienangehöriger",
}

export enum BorrowerSalutationType {
    MAN = "Herr",
    WOMAN = "Frau",
}

export enum BorrowerEducationType {
    MANDATORY_SCHOOL = "Pflichtschule",
    APPRENTICESHIP = "Lehre",
    HIGH_SCHOOL = "Matura",
    MASTERS_EXAM = "Meisterprüfung",
    MASTERS_UNIVERSITY = "MBA",
    TECHNICAL_OR_UNIVERSITY_QUALIFICATION = "Fach-/Hochschulabschluss",
    DOCTORATE = "Doktorat",
}

export enum DealPropertyBasementRoughSizeType {
    FULL = "Vollunterkellert",
    PARTIAL = "Teilunterkellert",
    NONE = "Kein Keller",
}

export enum DealPropertyRiskType {
    DANGER_ZONE = "Gefahrenzone",
    CONTAMINATED_SITES = "Altlastenverdacht",
}

export enum DealPropertyFloorType {
    NATURAL_STONE = "Naturstein-, Granit-, Terrakottaboden",
    VINYL = "Vinylboden, Laminat, Fertigparkett",
    CARPET = "Teppich-, Linol-, Kunststoffboden",
    PARQUET = "Parkett",
    SCREED = "Estrich",
}

export enum DealPropertyStatusType {
    NEW = "Erstbezug/Neuwertig",
    NORMAL = "Normal erhalten",
    NEEDS_RENOVATION = "Sanierungs-/renovierungsbedürftig",
    UNDER_CONSTRUCTION = "in Bau",
}

export enum BorrowerEmploymentDuration {
    PERMANENT = "Unbefristet",
    TEMPORARY = "Befristet",
    PROBATION = "Probezeit",
}

export enum DealPropertyLandDevelopmentPlannedType {
    WITHIN_TWO_YEARS = "innerhalb 2 Jahre",
    WITHIN_FIVE_YEARS = "innerhalb 5 Jahre",
    NOT_PLANNED = "nein",
}

export enum DealPropertyConstructionType {
    BRICK = "Massiv",
    WOOD_FRAME = "Holzriegelbau",
    OTHER = "Sonstiges:",
}

export enum DealAlreadyAskedBanks {
    YES = "Ja",
    NO = "Nein",
    NO_BUT_HAVE_AN_APPOINTMENT = "Nein, aber Termin bereits vereinbart",
}

export enum DealPropertySpecialOwnerType {
    NON_BORROWER = "Andere Nicht-Kreditnehmer",
}

export enum ConsultationFinancingChoice {
    VARIABLE = "Variabel",
    FIXED = "Fix",
    INTERIM = "Zwischenfinanzierung",
}

export enum BorrowerMainResidenceType {
    NON_PRIMARY = "Kein Hauptwohnsitz",
    LESS_THAN_6_MONTHS = "Weniger als 6 Monate",
    BETWEEN_6_AND_12_MONTHS = "6-12 Monate",
    BETWEEN_1_AND_3_YEARS = "1-3 Jahre",
    BETWEEN_3_AND_5_YEARS = "3-5 Jahre",
    OVER_5_YEARS = "5 Jahre oder mehr",
}

export enum BorrowerRoughEmployedSince {
    LESS_THAN_6_MONTHS = "weniger als 6 Monate",
    BETWEEN_6_AND_12_MONTHS = "6-12 Monate",
    BETWEEN_1_AND_3_YEARS = "1-3 Jahre",
    OVER_3_YEARS = "mehr als 3 Jahre",
}

export enum BorrowerAlimony {
    NONE = "Keine",
    TO_PAY = "Zu zahlen",
    TO_RECEIVE = "Zu erhalten",
}

export enum BorrowerMaternityLeaveModel {
    INCOME_DEPENDANT = "Einkommensabhängig",
    INCOME_INDEPENDANT = "Einkommensunabhängig",
}
