import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import { AuthContextProvider } from "./context/AuthContext";
import { LocaleContextProvider } from "./context/LocaleContext";
import { SettingsContextProvider } from "./context/SettingsContext";
import { ApplicationContainer } from "./ui/containers/ApplicationContainer";
import useGoogleAnalytics from "./ui/pages/survey/hooks/useGoogleAnalytics";
import { useUserId } from "./context/SurveyContext/hooks/useUserId";
import { useEffect } from "react";
import { sentryService } from "./service/sentry";
import { LocalizationProvider } from "./context/LocalizationProvider";
import { ReactQueryDevtools } from "react-query/devtools";
import { UserThemeProvider } from "./context/UserThemeProvider";
import { QueryParamsProvider } from "./providers/QueryParamsProvider";
import { ColorSchemeProvider } from "./context/ColorSchemeContext";

const queryClient = new QueryClient();

const ENABLE_REACT_QUERY_DEVTOOLS = false;

const App = () => {
    const userId = useUserId();

    useGoogleAnalytics(userId);

    useEffect(() => {
        if (!userId) {
            return;
        }

        sentryService.init(userId);
    }, [userId]);

    return (
        <QueryParamsProvider>
            <ToastContainer hideProgressBar autoClose={2500} />
            <QueryClientProvider client={queryClient}>
                {ENABLE_REACT_QUERY_DEVTOOLS && <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />}
                <AuthContextProvider>
                    <UserThemeProvider>
                        <ColorSchemeProvider>
                            <LocaleContextProvider>
                                <LocalizationProvider>
                                    <SettingsContextProvider>
                                        <ApplicationContainer />
                                    </SettingsContextProvider>
                                </LocalizationProvider>
                            </LocaleContextProvider>
                        </ColorSchemeProvider>
                    </UserThemeProvider>
                </AuthContextProvider>
            </QueryClientProvider>
        </QueryParamsProvider>
    );
};

export default App;
