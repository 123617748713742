import { createContext, ReactNode, useContext, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { ApplicationRoute } from "src/shared/types/route";

export enum AppColorScheme {
    TOOL = "tool",
    SURVEY = "survey",
}

type ColorSchemeContextData = {
    colorScheme: AppColorScheme;
};

const ColorSchemeContext = createContext<ColorSchemeContextData>({
    colorScheme: AppColorScheme.TOOL,
});

type ColorSchemeProviderProps = {
    children: ReactNode;
};

export function ColorSchemeProvider({ children }: ColorSchemeProviderProps) {
    const { pathname } = useLocation();

    const colorScheme = useMemo(() => {
        if (pathname.startsWith(ApplicationRoute.SURVEY) || pathname.startsWith(ApplicationRoute.ADDITIONAL_INFO)) {
            return AppColorScheme.SURVEY;
        }

        return AppColorScheme.TOOL;
    }, [pathname]);

    const value = useMemo(() => ({ colorScheme }), [colorScheme]);

    return <ColorSchemeContext.Provider value={value}>{children}</ColorSchemeContext.Provider>;
}

export const useColorScheme = () => useContext(ColorSchemeContext);
