import { SxProps, TextField, TextFieldProps, Typography } from "@mui/material";
import { ChangeEvent, Fragment, HTMLInputTypeAttribute, ReactNode, useState } from "react";
import { Control, Controller, Path, RegisterOptions } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { safeInputValue } from "src/utils/input";
import { GlowingBorderWrapper } from "../wrappers/GlowingBorderWrapper/GlowingBorderWrapper";
import { usePreventUpdateOnScroll } from "src/shared/hooks/form/usePreventUpdateOnScroll";
import { useTranslation } from "src/context/LocaleContext";

type NumberFormattingProps = {
    decimalSeparator?: string;
    thousandSeparator?: string;
    allowLeadingZeros?: boolean;
    fixedDecimalScale?: boolean;
    decimalScale?: number;
};

const defaultDecimalNumberFormattingProps: NumberFormattingProps = {
    decimalSeparator: ",",
    thousandSeparator: ".",
    allowLeadingZeros: false,
    fixedDecimalScale: true,
    decimalScale: 2,
};

const defaultWholeNumberFormattingProps: NumberFormattingProps = {
    decimalSeparator: ",",
    thousandSeparator: "",
    allowLeadingZeros: false,
    fixedDecimalScale: true,
    decimalScale: 0,
};

const getLabel = (focused: boolean, label: string | ReactNode, placeholder = null, required): string | ReactNode => {
    if (typeof label === "string") {
        let res = focused ? label : placeholder || label;
        res += required ? "*" : "";
        return res;
    }

    return (
        <>
            {label} {required ? "*" : ""}
        </>
    );
};

const getNumberFormatting = (isDecimal: boolean, formatting: NumberFormattingProps): NumberFormattingProps => {
    const defaultProps = isDecimal ? defaultDecimalNumberFormattingProps : defaultWholeNumberFormattingProps;

    return {
        ...defaultProps,
        ...formatting,
    };
};

const getSx = (sx: SxProps, whiteBg: boolean): SxProps => {
    return {
        ...sx,
        backgroundClip: "red",
        "&>label": { backgroundColor: "transparent" },

        ...(whiteBg && {
            "&>div": {
                backgroundColor: `#fff !important`,
            },
        }),
    };
};

type ControlledTextFieldProps<TFormFields, TFieldType extends HTMLInputTypeAttribute> = {
    name: Path<TFormFields>;
    control: Control<TFormFields, any>;
    rules?: Omit<RegisterOptions<any, any>, "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled">;
    type?: TFieldType;

    additionalOnChangeAction?: (value: string) => void;
    highlightIfEmpty?: boolean;
    display?: boolean;

    formatAsDecimal?: TFieldType extends "number" ? boolean : false;
    numberFormatInutMode?: "decimal" | "numeric";
    numberFormattingProps?: NumberFormattingProps;
    requiredText?: string;
    whiteBg?: boolean;
    disableDefaultError?: boolean;
    minValue?: number;
    maxValue?: number;
} & TextFieldProps;

export const ControlledTextField = <T extends any, F extends HTMLInputTypeAttribute>({
    name,
    control,
    rules,
    additionalOnChangeAction,
    highlightIfEmpty = false,
    display = true,
    formatAsDecimal = false,
    numberFormattingProps,
    numberFormatInutMode = "numeric",
    label = "",
    required = false,
    placeholder = "",
    onChange,
    onBlur,
    onFocus,
    requiredText = null,
    minValue = null,
    maxValue = null,
    whiteBg = false,
    disableDefaultError = false,
    type,
    ...rest
}: ControlledTextFieldProps<T, F>) => {
    const { t } = useTranslation();
    const [focused, setFocused] = useState(false);
    const inputRef = usePreventUpdateOnScroll();
    const { variant, size, fullWidth, prefix, sx } = rest;

    if (!display) {
        return null;
    }
    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({
                field: { value, onChange: controllerOnChange, onBlur: controllerOnBlur },
                fieldState: { error },
            }) => {
                const inputLabel = getLabel(focused, label, placeholder, required);
                const disableGlowingBorder = !highlightIfEmpty || Boolean(safeInputValue(value));
                const inputSx = getSx(sx, whiteBg);
                const isNumber = type === "number";

                const inputOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
                    controllerOnBlur?.();
                    onBlur?.(event);
                    setFocused(false);
                };

                const inputOnFocus = (event: React.FocusEvent<HTMLInputElement>) => {
                    onFocus?.(event);
                    setFocused(true);
                };

                const numericOnChange = (v) => {
                    controllerOnChange(v.floatValue ?? "");
                    additionalOnChangeAction?.(String(v.floatValue));
                };

                const textualOnChange = (event: ChangeEvent<HTMLInputElement>) => {
                    const val = event.target.value;
                    const numberVal = Number(val);
                    onChange ? onChange(event) : controllerOnChange(isNumber && !isNaN(numberVal) ? numberVal : val);
                    additionalOnChangeAction?.(val);
                };

                const isRequiredErrorType = error?.type === "required" || error?.type === "optionality";

                return (
                    <Fragment>
                        <GlowingBorderWrapper inheritSx={true} disabled={disableGlowingBorder}>
                            {isNumber ? (
                                <NumericFormat
                                    id={name}
                                    inputRef={inputRef}
                                    label={inputLabel}
                                    value={value}
                                    onValueChange={numericOnChange}
                                    error={Boolean(error)}
                                    onBlur={inputOnBlur}
                                    onFocus={inputOnFocus}
                                    variant={variant}
                                    size={size}
                                    fullWidth={fullWidth}
                                    prefix={prefix}
                                    customInput={TextField}
                                    inputProps={{
                                        inputMode: numberFormatInutMode,
                                    }}
                                    InputProps={rest.InputProps}
                                    sx={inputSx}
                                    {...getNumberFormatting(formatAsDecimal, numberFormattingProps)}
                                />
                            ) : (
                                <TextField
                                    id={name}
                                    inputRef={inputRef}
                                    label={inputLabel}
                                    value={value ?? ""}
                                    error={Boolean(error)}
                                    onChange={textualOnChange}
                                    onBlur={inputOnBlur}
                                    onFocus={inputOnFocus}
                                    sx={inputSx}
                                    type={type}
                                    {...rest}
                                />
                            )}
                        </GlowingBorderWrapper>
                        {isRequiredErrorType && requiredText && (
                            <Typography color="red" fontSize={"12px !important"} marginTop={1}>
                                {requiredText}
                            </Typography>
                        )}
                        {error?.type === "min" && minValue && (
                            <Typography color="red" fontSize={"12px !important"} marginTop={1}>
                                {t("validation.fieldMinimum").replace("{{minimum}}", String(minValue))}
                            </Typography>
                        )}
                        {error?.type === "max" && maxValue && (
                            <Typography color="red" fontSize={"12px !important"} marginTop={1}>
                                {t("validation.fieldMaximum").replace("{{maximum}}", String(maxValue))}
                            </Typography>
                        )}
                        {!disableDefaultError && !!error && !isRequiredErrorType && error.message && (
                            <Typography color="red" fontSize={"12px !important"} marginTop={1}>
                                {error.message}
                            </Typography>
                        )}
                    </Fragment>
                );
            }}
        />
    );
};
